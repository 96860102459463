import { get } from "../../../Utils/Crypto";

const DEVELOPMENT_MODE = window.location.href.includes("localhosts") || window.location.href.includes('https://employee-eka.netlify.app/');

let api, luna, token, track;

if (DEVELOPMENT_MODE) {
	api = "https://heimdall.expanso.in/scanners/";
	track = "https://track.expanso.in/scanners/";
	luna = "luna-staging";
	token = () => get("refresh_token");
} else {
	api = "https://heimdall.eka.io/scanners/";
	luna = "luna";
	token = () => get("refresh_token");
}

track = "https://track.eka.io/scanners/";


export {
	api, luna, DEVELOPMENT_MODE, token, track
}
