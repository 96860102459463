import React, { useRef } from 'react'
import { connect } from "react-redux";
import { attemptlogin } from '../../Redux/Actions/LoginController';
import { showToast } from '../../Component/Common/Toast';
import bs from '../../Assets/bootstrap.module.css';
import Lock from './lock.png';
import Back from './abstract.jpg';

function Index(props) {

	let Email = useRef(null);
	let Password = useRef(null);

	const _onClick = () => {

		let username = Email.current.value;
		let pass = Password.current.value;

		if (!username) {
			showToast({
				type: 'error',
				message: "Please enter the email"
			})
			return;
		}

		if (!pass || pass.length < 4) {
			showToast({
				type: 'error',
				message: "Please enter a valid password"
			})
			return;
		}

		props.login(username, pass)
	}

		if (props.auth.authenticated) {
			props.history.push("/app/p/main")
		}
		return (
			<>
			<div style={{
				background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
				height: '100vh',
				fontSize: '16px'
				}}>
					<div className={`${bs.container} ${bs['h-100']}`} style={{maxWidth: '1500px'}}>
						<div style={{color: '#111'}} className={`${bs.row} ${bs['justify-content-center']} ${bs['align-items-center']} ${bs['h-100']}`}>
							<div data-aos="fade-right" className={`FadeLeft-Effect ${bs['col-md-6']} ${bs['d-none']} ${bs['d-sm-none']} ${bs['d-md-flex']} ${bs['text-white']}`}>
								<div style={{maxWidth: '400px', margin: 'auto'}}>
									<h6 className={bs['fw-bold']}>Quick Tips</h6>
									<h1 className={`${bs['display-3']} ${bs['fw-bold']}`}>Dice Scanner Room.</h1>
									<p>Dice Venor portal makes your client onboarding process fast and smooth, with everything
										availalbe at one place.</p>
								</div>
							</div>
							<div className={bs['col-md-6']}>
	
								<div data-aos="fade-left" className={`FadeRight-Effect ${bs['p-5']} ${bs['text-center']}`} style={{
									backdropFilter: 'blur(10px)',
									margin: 'auto',
									backgroundColor: 'rgba(255,255,255,0.8)',
									borderRadius: '1rem',
									maxWidth: '400px',
									boxShadow: '0 1rem 2rem rgba(0,0,0,0.3)',
									minWidth: '380px'
								}}>
									<img className={`${bs['img-fluid']} ${bs['mb-4']} ${bs['m-auto']}`} src={Lock} style={{maxWidth: '80px'}} alt=""/>
									<h1 className={`${bs['display-6']} ${bs['fw-bold']}`}>Log in</h1>
									<p>Welcome, enter your Email</p>
									<form onSubmit={(e) => {
										e.preventDefault();
										_onClick();
									}}>
										<div className={`${bs['mt-5']} ${bs['mb-4']}`}>
											<input ref={Email} type="text" className={`${bs['form-control']} ${bs['mb-2']}`}
												placeholder="Username"/>
											<input ref={Password} type="password" className={`${bs['form-control']} ${bs['mb-2']}`}
												placeholder="Password"/>
										</div>
										<button type='submit' className={`${bs.btn} ${bs['btn-primary']} ${bs['w-100']}`} x="button">Log in</button>
									</form>
									<p className={bs['mt-3']} style={{fontSize: '14px'}}>
										<a style={{fontWeight: 400}} className={`${bs['text-primary']} ${bs.links}`} href="#1">Forgot Password</a>
										<a style={{fontWeight: 400}} className={bs['text-primary']} href="#2">&nbsp; | &nbsp;</a>
										<a style={{fontWeight: 400}} className={`${bs['text-primary']} ${bs.links}`} href="/signup">Sign Up
											&nbsp;
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
												fill="currentColor" viewBox="0 0 16 16"
												className={`${bs.bi} ${bs['bi-arrow-right-circle-fill']}`}>
												<path
													d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
											</svg>
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (email, password) => {
			dispatch(attemptlogin(email, password))
		}
	}
})(Index);
