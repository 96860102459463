import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as ServiceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("/firebase-messaging-sw.js")
		.then(function (registration) {
			console.log("Registration successful, scope is:", registration.scope);
		})
		.catch(function (err) {
			console.log("Service worker registration failed, error:", err);
		});

	ServiceWorker.register({
		onUpdate: reg => {
			const waitingServiceWorker = reg.waiting
			waitingServiceWorker.addEventListener("statechange", event => {
				if (event.target.state === "activated") {
					console.log("Updated")
				}
			});
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
		}
	})
}

