import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Login';
import Token from './Login/token';
import ResetPassword from './Login/reset';
import { asyncComponent } from '../Utils/Async';
import './index.css';
import '../Assets/theme.css';

function Index(props) {
	if(window.localStorage.getItem('SCANNERS_THEME')==="DARK"){
		document.getElementById('bodyWrap').className = 'Dark'
	}else{
		document.getElementById('bodyWrap').className = 'Light'
	}
	return (
		<div>
			<BrowserRouter>
				<Switch>
					<Route path="/" component={Login} exact={true} />
					<Route path="/login" component={Token} exact={true} />
					<Route path="/reset" component={ResetPassword} exact={true} />
					<Route path="/app" component={asyncComponent(() => import("./AuthenticatedController"))} />
				</Switch>
			</BrowserRouter>
		</div>
	)
}

export default Index
