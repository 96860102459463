import { api, track } from '../Api/config';
import axios from 'axios';
import qs from 'qs'
import { trackData } from "../Track/tracker";
import * as Crypto from '../../../Utils/Crypto';
import Moment from 'moment'

const CancelToken = axios.CancelToken;
let isRefreshing = false;


axios.defaults.baseURL = api;
axios.defaults.timeout = 62500;
axios.interceptors.response.use(response => {
	return response;
}, function (error) {
	const err = error.response;
	if (err.status === 401 && err.config) {
		const originalRequest = err.config;
		if (!isRefreshing) {
			isRefreshing = true;
			getRefreshToken()
				.then(function (success) {
					onRefreshed(success.data.access_token)
				}).catch(err => {
					window.localStorage.removeItem('t');
					window.localStorage.removeItem('f');
					window.localStorage.removeItem('s');
					window.localStorage.removeItem('loggedin');
					window.localStorage.removeItem('last_active_at');
					window.location.reload();
				});
		}
		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(token => {
				originalRequest.headers['Authorization'] = 'Bearer ' + token;
				resolve(axios(originalRequest));
			});
		});
	} else {
		return Promise.reject(err);
	}
});

axios.interceptors.request.use(async function (config) {
	const token = Crypto.get('token');
	if (token !== null && token !== "") {
		config.headers.Authorization = `Bearer ${token}`;

	}
	return config;
}, function (err) {
	return Promise.reject(err);
});

function getRefreshToken() {
	let refreshtoken = Crypto.get("refresh_token");
	if (!refreshtoken) {
		window.localStorage.removeItem('t');
		window.localStorage.removeItem('f');
		window.localStorage.removeItem('s');
		window.localStorage.removeItem('loggedin');
		window.localStorage.removeItem('last_active_at');
		window.location.reload();
	}
	return axios.post(`${api}auth/token/refresh`, {
		token: refreshtoken
	});
}


const Cancellable = (promise, mCancel = false) => {
	let hasCompleted = false;
	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			val => {
				if (hasCompleted) {
					reject({ isCanceled: true })
				} else {
					hasCompleted = true;
					resolve(val)
				}
			},
			error => {
				if (hasCompleted) {
					reject({ isCanceled: true })
				} else {
					reject(error);
				}
			}
		);
	});
	return {
		promise: wrappedPromise,
		cancel() {
			hasCompleted = true;
			if (mCancel) {
				mCancel()
			}
		},
		isCancellable() {
			return !hasCompleted;
		}
	};
};

let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const onRefreshed = (token) => {
	setTokens(token);
	isRefreshing = false;
	refreshSubscribers = refreshSubscribers.filter(cb => {
		cb(token);
		return false;
	});
	refreshSubscribers = [];
}

const setTokens = (token) => {
	Crypto.set('token', token);
}


const get = (url, cb, data = {}) => {
	let currentTs = Moment().valueOf();
	let cancel = false;
	let cancelToken = new CancelToken(function executor(c) {
		cancel = c;
	});
	let cancellable = Cancellable(
		axios.get(url, {
			params: data,
			paramsSerializer: params => {
				return qs.stringify(params, { arrayFormat: 'repeat' })
			},
			cancelToken
		}), cancel
	);
	cancellable.promise.then(res => {
		cb(null, res.data)
		{
			let responseTs = Moment().valueOf();
			let time = responseTs - currentTs;
			data.code = `200`;
			data.time = `${time}`;
			pushRequest(url, data, "get");
		}
	}).catch(err => {
		if (err && !err.isCanceled) {
			{
				let responseTs = Moment().valueOf();
				let time = responseTs - currentTs;
				data.code = `${err.code}`;
				data.time = `${time}`;
				data.error = err.data;
				pushRequest(url, data, "get");
			}
			cb(err)
		}
	});
	return cancellable;
}

const post = (url, data, cb) => {
	let currentTs = Moment().valueOf();
	let cancellable = Cancellable(
		axios.post(url, data)

	);
	cancellable.promise.then(res => {
		cb(null, res.data)
		{
			let responseTs = Moment().valueOf();
			let time = responseTs - currentTs;
			data.code = `200`;
			data.time = `${time}`;
			pushRequest(url, data, "post");
		}
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
			{
				let responseTs = Moment().valueOf();
				let time = responseTs - currentTs;
				data.code = `${err.code}`;
				data.time = `${time}`;
				if (err.data)
					data.error = err.data.error;
				pushRequest(url, data, "post");
			}
		}
	});

	return cancellable;
}


const patch = (url, data, cb) => {
	pushRequest(url, data, "patch");
	let cancellable = Cancellable(
		axios.patch(url, data)
	);
	cancellable.promise.then(res => {
		cb(null, res.data)
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
		}
	});
	return cancellable;
}





const pushRequest = (url, data, method, baseUrl) => {
	if (!baseUrl) {
		baseUrl = api;
	}
	if (!data) {
		data = {}
	}
	const token = Crypto.get('token');
	let session = "Not Defined";
	if (token) {
		session = token.substr(40, 30);
	}
	let request = {
		sessionId: session,
		data: {
			...data,
			apiUrl: baseUrl + url
		},
		platform: "dashboard",
		method,
		type: "api"
	};
	trackData(request);
}

const trackApis = (data) => {
	axios.post(track, data)
}





export default axios;
export {
	post, get, axios, patch, trackApis
}
