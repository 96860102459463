import React from 'react';
import { Provider } from 'react-redux';
import Store from './Redux';
import './Assets/styles.css'
import './Assets/index.css'
import './Assets/app.css'
import './Assets/shine.css'
import { ToastContainer } from './Component/Common/Toast/ToastContainer';
import Container from './Pages'
const ReduxStore = Store();

function App(props) {
return (
        <Provider store={ReduxStore}>
            <ToastContainer/>
            <Container/>
        </Provider>
    )
}

export default App;
